<template>
    <div class="card">
        <!-- <div class="card-header">
            <h1>{{ post.title }}</h1>
            <small>{{ post.category }}</small>
            <div class="actions">
                <button class="btn btn-danger btn--icon" @click="back">
                    <i class="zmdi zmdi-arrow-back"></i>
                </button>
                <button class="btn btn-warning btn--icon" @click="edit">
                    <i class="zmdi zmdi-edit"></i>
                </button>
            </div>
        </div> -->
        <div v-if="post.photo != ''">
            <img :src="post.photo" class="preview-photo">
        </div>
        <div class="card-body">

            <!-- <h1>{{ post.title }}</h1> -->
            <!-- <small>{{ post.category }}</small> -->
            <!-- <div class="actions">
                <button class="btn btn-danger btn--icon" @click="back">
                    <i class="zmdi zmdi-arrow-back"></i>
                </button>
                <button class="btn btn-warning btn--icon" @click="edit">
                    <i class="zmdi zmdi-edit"></i>
                </button>
            </div> -->

            <Processing v-if="isProcessing" />
            <div class="post-content" v-else>

                <div class="actions">
                    <button class="btn btn-danger btn--icon" @click="back">
                        <i class="zmdi zmdi-arrow-back"></i>
                    </button>
                    <button class="btn btn-warning btn--icon" @click="edit(post.id)">
                        <i class="zmdi zmdi-edit"></i>
                    </button>
                </div>
                
                <h4 class="mt-4">{{ post.title }}</h4>
                <p class="mb-4">{{ post.category }}</p>
                <dl>
                    <dt>Content</dt>
                    <dd>{{ post.content }}</dd>
                </dl>
                <dl>
                    <dt>Date</dt>
                    <dd>{{ $moment(post.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss a") }}</dd>
                </dl>
                <dl>
                    <dt>Recorded By</dt>
                    <dd>{{ post.author.email }}</dd>
                </dl>
            </div>
        </div>
    </div>

    <!-- <section class="content">
        <Processing v-if="isProcessing" />
        <div class="content__inner content__inner--sm" v-else>
            <header class="content__title">
                <h1>{{ post.title }}</h1>
                <small>{{ post.category }}</small>
                <div class="actions">
                    <button class="btn btn-danger btn--icon" @click="back">
                        <i class="zmdi zmdi-arrow-back"></i>
                    </button>
                    <button class="btn btn-warning btn--icon" @click="edit">
                        <i class="zmdi zmdi-edit"></i>
                    </button>
                </div>
            </header>
        </div>
    </section> -->

</template>

<script>
export default {
    data(){
        return {
            post: {},
            isProcessing: false,
            postId: this.$route.params.newsId,
        }
    },
    created(){
        console.log(this.$route.params.newsId);
        this.viewNews()
    },
    methods: {
        async viewNews(){
            this.isProcessing = true
            const snapshot = await this.$db.collection("news").doc(this.$route.params.newsId).get()
            this.post = { id: snapshot.id, ...snapshot.data() }
            this.isProcessing = false
        },
        back(){
            this.$router.go(-1)
        },
        edit(){
            this.$router.push("/news/n/" + this.postId + "/edit")
        },
    },
    components: {
        Processing: () => import("@/components/Loaders/Processing.vue")
    }
}
</script>


<style scoped>
.post-content {
    white-space: pre-line;
}
.preview-photo {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.actions {
    display: flex;
}
.actions button {
    margin-left: 5px;
}
</style>